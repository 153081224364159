/**
 * Flag to indicate if menu is visible (mobile only)
 */
var menuVisible = false;
/**
 * Toggle the mobile menu visible/invisible
 */
function toggleMenu() {
    /* toggle the open/closed value */
    menuVisible = !menuVisible;
    /* build the list of class names */
    var classList = 'menu menu-' + (menuVisible ? 'open' : 'closed');
    /* apply the class list */
    var menu = document.getElementById('menu');
    menu.setAttribute('class', classList);
}
/**
 * Apply the input styling
 * @param id
 * @param event
 */
function applyInputStyle(id, event) {
    var input = document.getElementById(id + '-text');
    var text = '';
    if (input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement)
        text = input.value;
    /* determine which classes to apply */
    var classList = 'form-input-label-empty';
    if (text.length > 0 || event.type === 'focus')
        classList += ' form-input-label';
    /* apply the classes */
    var label = document.getElementById(id + '-label');
    if (label)
        label.setAttribute('class', classList);
}
/**
 * Update the form with contact information
 * @param event
 */
function changeContactMethod(event) {
    var method = event.target.value;
    var unMethod = method === 'phone' ? 'email' : 'phone';
    document.getElementById('input-' + method).setAttribute('class', 'input-label');
    document.getElementById('input-' + unMethod).setAttribute('class', 'input-label hidden');
}
/**
 * Hide the scroll icon after scrolling down a bit
 */
function hideScrollIconListener() {
    /* choose the scroll class to apply */
    var styleClass = this.scrollTop > 200 ? 'hidden' : 'scroll-down';
    /* set the icon element's class to hidden */
    var icon = document.getElementById('scroll-icon');
    icon.setAttribute('class', styleClass);
}
/**
 *
 */
function init() {
    applyInputStyle('input-email', { 'type': 'init' });
    applyInputStyle('input-message', { 'type': 'init' });
    window.addEventListener('load', function () {
        var content = document.getElementById('main-content-body');
        content.addEventListener('scroll', hideScrollIconListener.bind(content));
    });
}
init();
